/* eslint-disable import/export */
import { z } from "zod";

import { naturalNumber } from "./numbers";

export const userRole = z.enum(["user", "admin", "super_admin"]);

export type userRole = z.infer<typeof userRole>;

/** @deprecated We should just use the domain model instead of having a custom
    type here. This will require changes to the FE to work with account-brands
    rather than accounts.
 */
export const user = z.object({
  userId: z.string().uuid(),
  email: z.string().email(),
  role: userRole,
  status: z.string(),
  accounts: z
    .object({
      accountId: naturalNumber,
      accountName: z.string(),
    })
    .array(),
});

export namespace user {
  export type In = z.input<typeof user>;
}
