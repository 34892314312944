import { useFocusWithin } from "$src/hooks/use-focus-within";
import { cx } from "$src/lib/utils";
import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

import { ControlledCollapsible } from "../collapsible/collapsible";
import styles from "./input.module.css";

export type InputProps = {
  /** Label for the input */
  label: string;
  /** Whether the input is invalid */
  invalid?: boolean;
  /** Optional error on the input */
  errorMessage?: string | null;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Optional extra info text for the field */
  info?: string;
  /** Optional extra info displayed on focus for the field */
  focusInfo?: string;
  /** Optional end adornment */
  endAdornment?: React.ReactNode;
  /** Whether the input is clearable */
  clearable?: boolean;
  /** Icon */
  icon?: React.ComponentType<{ className?: string }>;
  /** Callback on clear */
  onClear?(): void;
} & React.ComponentPropsWithoutRef<"input">;

/**
 * @component
 * Input with floating label
 */
export const Input = ({
  label,
  invalid,
  errorMessage,
  disabled,
  info,
  focusInfo,
  endAdornment,
  clearable,
  onClear,
  value,
  onChange,
  className,
  icon: Icon,
  ...props
}: InputProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const isFocused = useFocusWithin(wrapper);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(!!value || isFocused);
  }, [value, isFocused, input]);

  return (
    <div className={className}>
      <div
        data-testid="input-wrapper"
        className={cx(
          styles.input,
          disabled && styles.disabled,
          active && styles.active,
          (invalid ?? errorMessage) && styles.error,
        )}
        onClick={() => input.current?.focus()}
        ref={wrapper}
      >
        <label className={styles["input-inner"]}>
          <span className={styles.label}>{label}</span>
          <ControlledCollapsible open={active} fast staggered>
            <input
              data-testid="input-input"
              ref={input}
              className={styles["input-field"]}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                onChange?.(e);
              }}
              {...props}
            />
          </ControlledCollapsible>
        </label>
        {endAdornment}
        {clearable && !invalid && !!value ? (
          <X
            className={styles.icon}
            data-testid="input-clear"
            onClick={() => {
              onClear?.();
            }}
          />
        ) : (
          Icon && <Icon className={styles.icon} data-testid="input-icon" />
        )}
      </div>
      {info && (
        <div className={cx(styles.info, disabled && styles["disabled-info"])}>
          {info}
        </div>
      )}
      {focusInfo && (
        <ControlledCollapsible open={isFocused} fast staggered>
          <div className={styles.info} data-testid="input-focus-info">
            {focusInfo}
          </div>
        </ControlledCollapsible>
      )}
      {errorMessage && invalid && (
        <div className={styles["error-message"]}>{errorMessage}</div>
      )}
    </div>
  );
};
