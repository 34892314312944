import { z } from "zod";

export const EnsureUserActivationLoggedResult = z.object({
  isFirstActivation: z.boolean(),
  activatedAt: z.string().datetime({ offset: true }),
});

export type EnsureUserActivationLoggedResult = z.infer<
  typeof EnsureUserActivationLoggedResult
>;
export namespace EnsureUserActivationLoggedResult {
  export type In = z.input<typeof EnsureUserActivationLoggedResult>;
}
