import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const GeographyId = IntoNat.brand("geographyId");

export type GeographyId = z.infer<typeof GeographyId>;

export const GeographyName = z.string().brand("GeographyName");

export type GeographyName = z.infer<typeof GeographyName>;

export const GeographyCode = z.string().brand("GeographyCode");

export type GeographyCode = z.infer<typeof GeographyCode>;

export const Geography = z.object({
  id: GeographyId,
  name: GeographyName,
  code: GeographyCode.optional(),
});

export type Geography = z.infer<typeof Geography>;
