/* v8 ignore start */
// This hook just initialises Segment, nothing to test
import { useAccount } from "$src/stores/use-account";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { BFFOutput } from "@tracksuit/bff/trpc";

export enum AnalyticsEvents {
  PageView = "Viewed Page",
  SessionLength = "Session Length",
  ExpandAccordion = "Expand Accordion",
  SwappedCompetitor = "Swapped Competitor",
  ChangeDataType = "Change Data Type",
  FilterDate = "Filter Dates",
  FilterBrand = "Filter Brand",
  FilterDemographics = "Filter Demographics",
  ChangeMetric = "Change Metric",
  Download = "Download",
  Comparison = "Compare Date Ranges",
  FilterUniqueWords = "Filter Unique Words",
  SummaryOpened = "Summary Opened",
  SummaryGenerated = "Summary Generated",
  SummaryError = "Summary Errored",
  SummaryCancelled = "Summary Cancelled",
  Copy = "Copy",
  SelectBrand = "Selected Active Brand",
  SelectMetric = "Selected Active Metric",
  CopyImage = "Copy Image",
  Verbatim = "Viewed Verbatim",
  OpenStatement = "Opened Statement",
  OpenSettings = "Opened Settings Menu",
  ChangeAccount = "Changed Account",
  OpenLink = "Opened Link",
  OpenProfile = "Opened Profile",
  AddMilestone = "Added Milestone",
  EditMilestone = "Edited Milestone",
  DeleteMilestone = "Deleted Milestone",
  ChangeMilestoneVisibility = "Changed Milestone Visibility",
  EasterEgg = "Easter Egg Triggered",
  ChangedConversionOptions = "Changed Conversion Options",
  ChangedTimelineOptions = "Changed Timeline Options",
  HideItem = "Hide Item",
  ShowItem = "Show Item",
  OpenInsightOverview = "Opened Insight Overview",
  OpenDemographicDriver = "Opened Demographic Driver",
  ExpandedEllipsis = "Expanded Ellipsis",
  EducationOpened = "Education Opened",
  OnboardingStepComplete = "Onboarding Step Completed",
  OnboardingSubmitted = "Onboarding Submitted",
  SignUpOpened = "Sign-up Opened",
  SignUpSuccess = "Sign-up Success",
  SignInOpened = "Sign-in Opened",
  SignInSuccess = "Sign-in Success",
  SignInMFAChallengeOpened = "Sign-in MFA Challenge Opened",
  AreaChart = "Area chart legend click",
  ConversionDriver = "Conversion Driver clicked",
  TooltipActivated = "Tooltip Activated",
}

export const getAnalytics = (
  account?: NonNullable<BFFOutput["metadata"]["list"]>[0],
  pathname?: string,
  customSegmentDomain?: boolean,
): AnalyticsBrowser | undefined => {
  if (import.meta.env.MODE !== "production") {
    return undefined;
  }

  // Instructions for using a custom proxy: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/#npm-instructions
  // Use this endpoint to get the settings for cdnSettings: https://analytics-cdn.gotracksuit.com/v1/projects/CFWwEZBkHAlPzNh58bWU5U9g2LNO08pp/settings
  const {
    track: _track,
    page: _page,
    ...rest
  } = AnalyticsBrowser.load(
    customSegmentDomain
      ? {
          writeKey: "CFWwEZBkHAlPzNh58bWU5U9g2LNO08pp",
          cdnSettings: {
            integrations: {
              "Segment.io": {
                apiKey: "CFWwEZBkHAlPzNh58bWU5U9g2LNO08pp",
                apiHost: "analytics-api.gotracksuit.com/v1",
              },
            },
            analyticsNextEnabled: true,
            metrics: { sampleRate: 0.1 },
            legacyVideoPluginsEnabled: false,
            autoInstrumentationSettings: {
              disableTraffic: false,
              sampleRate: 0,
            },
          },
        }
      : {
          writeKey: "CFWwEZBkHAlPzNh58bWU5U9g2LNO08pp",
        },
  );
  const accountProps = {
    accountBrandId: account?.accountBrandId,
    brand: account?.brandName,
    geography: account?.geography.name,
    category: account?.category.name,
  };

  return {
    track(eventName, properties, ...args) {
      return _track(
        eventName,
        {
          ...accountProps,
          route: pathname,
          ...properties,
        },
        ...args,
      );
    },
    page(category, name, properties, ...args) {
      return _page(
        category,
        name,
        {
          ...accountProps,
          ...properties,
        },
        ...args,
      );
    },
    ...rest,
  } as AnalyticsBrowser;
};

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();
  const { pathname } = useLocation();
  const account = useAccount((s) => s.active);
  const { customSegmentDomain } = useFlags();

  useEffect(() => {
    if (!analytics && account) {
      setAnalytics(getAnalytics(account, pathname, customSegmentDomain));
    }
  }, [account, customSegmentDomain]);

  return analytics;
};
/* v8 ignore end */
