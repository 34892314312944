import { z } from "zod";

import { AccountBrandId } from "@tracksuit/metadata/models";

import { Operations } from "./operations";

export namespace Actions {
  export const ViewDashboard = z.object({
    operation: z.literal(Operations.enum.ViewDashboard),
    accountBrand: AccountBrandId,
  });

  export type ViewDashboard = z.infer<typeof ViewDashboard>;

  export const ViewAnyDashboard = z.object({
    operation: z.literal(Operations.enum.ViewAnyDashboard),
  });

  export type ViewAnyDashboard = z.infer<typeof ViewAnyDashboard>;

  export const InviteDashboardAccess = z.object({
    operation: z.literal(Operations.enum.InviteDashboardAccess),
    accountBrand: AccountBrandId,
  });

  export type InviteDashboardAccess = z.infer<typeof InviteDashboardAccess>;

  export const RevokeDashboardAccess = z.object({
    operation: z.literal(Operations.enum.RevokeDashboardAccess),
    accountBrand: AccountBrandId,
  });

  export type RevokeDashboardAccess = z.infer<typeof RevokeDashboardAccess>;

  export const ManageDashboard = z.object({
    operation: z.literal(Operations.enum.ManageDashboard),
    accountBrand: AccountBrandId,
  });

  export type ManageDashboard = z.infer<typeof ManageDashboard>;

  export const DelegateManageDashboard = z.object({
    operation: z.literal(Operations.enum.DelegateManageDashboard),
    accountBrand: AccountBrandId,
  });

  export type DelegateManageDashboard = z.infer<typeof DelegateManageDashboard>;

  export const ModifyDashboardUserAttributes = z.object({
    operation: z.literal(Operations.enum.ModifyDashboardUserAttributes),
    accountBrand: AccountBrandId,
  });

  export type ModifyDashboardUserAttributes = z.infer<
    typeof ModifyDashboardUserAttributes
  >;

  export const EnumerateDashboardUsers = z.object({
    operation: z.literal(Operations.enum.EnumerateDashboardUsers),
    accountBrand: AccountBrandId,
  });

  export type EnumerateDashboardUsers = z.infer<typeof EnumerateDashboardUsers>;

  export const EnumerateDashboards = z.object({
    operation: z.literal(Operations.enum.EnumerateDashboards),
  });

  export type EnumerateDashboards = z.infer<typeof EnumerateDashboards>;
}

/** Represents a user's entitlement to perform an operation. */
export const Action = z.discriminatedUnion("operation", [
  Actions.ViewDashboard,
  Actions.ViewAnyDashboard,
  Actions.InviteDashboardAccess,
  Actions.ManageDashboard,
  Actions.RevokeDashboardAccess,
  Actions.DelegateManageDashboard,
  Actions.ModifyDashboardUserAttributes,
  Actions.EnumerateDashboardUsers,
  Actions.EnumerateDashboards,
]);

export type Action = z.infer<typeof Action>;

export namespace Action {
  export type In = z.input<typeof Action>;
}
