import { z } from "zod";

import { SubjectId } from "@tracksuit/cognito/models";

export const UserActivation = z.object({
  sub: SubjectId,
  activatedAt: z.string().datetime({ offset: true }),
});

export type UserActivation = z.infer<typeof UserActivation>;
export namespace UserActivation {
  export type In = z.input<typeof UserActivation>;
}
