import { z } from "zod";

import { FilterType } from "./filters";
import { DisplayPercentage } from "./percentage";
import { StdQuestion } from "./question";

export const Movement = z.object({
  questionType: StdQuestion,
  percentage: DisplayPercentage,
  toPercentage: DisplayPercentage,
  isSignificant: z.boolean(),
  supportingMovements: z
    .object({
      filterType: FilterType,
      filter: z.string(),
      percentage: DisplayPercentage,
    })
    .array(),
});

export type Movement = z.infer<typeof Movement>;
