/* v8 ignore start */
// This whole file is just consts, nothing to test
import { z } from "zod";

import { type QuestionType } from "@tracksuit/frontend/schemas";

export const DEFAULT_ERROR_MESSAGE =
  "We are currently having trouble accessing your latest data.";

export const ACCOUNT_SWITCH_EVENT = "accountBrandSwitch";

export const METRIC_COLORS: {
  [stage in QuestionType | "CATEGORY_PENETRATION"]?: string;
} = {
  UNPROMPTED_AWARENESS: "#EDAE49",
  PROMPTED_AWARENESS: "var(--color-purple-500)",
  CONSIDERATION: "var(--color-orange-500)",
  INVESTIGATION: "var(--color-blue-500)",
  USAGE: "var(--color-teal-500)",
  PREFERENCE: "var(--color-grape-500)",
  CATEGORY_PENETRATION: "var(--color-green-800)",
};

export const COMPETITOR_COLORS = [
  {
    primary: "var(--color-teal-500)",
    secondary: "var(--color-teal-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-orange-500)",
    secondary: "var(--color-orange-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-blue-500)",
    secondary: "var(--color-blue-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-yellow-500)",
    secondary: "var(--color-yellow-300)",
    text: "var(--color-text)",
  },
  {
    primary: "var(--color-grape-500)",
    secondary: "var(--color-grape-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-coral-500)",
    secondary: "var(--color-coral-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-peacock-500)",
    secondary: "var(--color-peacock-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-fawn-500)",
    secondary: "var(--color-fawn-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-charcoal-500)",
    secondary: "var(--color-charcoal-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-sage-500)",
    secondary: "var(--color-sage-300)",
    text: "var(--color-off-white)",
  },
  {
    primary: "var(--color-cherry-500)",
    secondary: "var(--color-cherry-300)",
    text: "var(--color-off-white)",
  },
];

export const COMPETITOR_AVG_COLOR = "var(--color-purple-400)";
export const CATEGORY_PENETRATION_COLOR = "var(--color-green-800)";

export const COMPETITOR_AVERAGE_ID = 1;
export const CATEGORY_PENETRATION_ID = 2;

export const STAGE_EXPLANATIONS: {
  [key in QuestionType]?: {
    verb: string;
    question: string;
    explanation: string;
  };
} = {
  UNPROMPTED_AWARENESS: {
    verb: "are",
    question: "aware",
    explanation: "aware of the brand without being prompted",
  },
  PROMPTED_AWARENESS: {
    verb: "are",
    question: "aware",
    explanation: "aware of the brand when prompted",
  },
  CONSIDERATION: {
    verb: "would",
    question: "consider",
    explanation: "consider using the brand",
  },
  INVESTIGATION: {
    verb: "have",
    question: "investigated",
    explanation: "investigated the brand",
  },
  USAGE: {
    verb: "have",
    question: "used",
    explanation: "used the brand",
  },
  PREFERENCE: {
    verb: "would",
    question: "prefer",
    explanation: "prefer to use the brand most",
  },
  QUALIFYING: {
    verb: "are",
    question: "in the category",
    explanation: "in the category",
  },
};

// Auth error codes from AWS Cognito
export const AUTH_ERROR_CODES = {
  CODE_DELIVERY_FAILURE: "CodeDeliveryFailureException",
  FORBIDDEN: "ForbiddenException",
  INTERNAL_ERROR: "InternalErrorException",
  INVALID_EMAIL_ROLE: "InvalidEmailRoleAccessPolicyException",
  INVALID_LAMBDA_RESPONSE: "InvalidLambdaResponseException",
  INVALID_PARAMETER: "InvalidParameterException",
  INVALID_PASSWORD: "InvalidPasswordException",
  INVALID_SMS_ROLE_ACCESS: "InvalidSmsRoleAccessPolicyException",
  INVALID_SMS_ROLE_TRUST: "InvalidSmsRoleTrustRelationshipException",
  LIMIT_EXCEEDED: "LimitExceededException",
  NOT_AUTHORIZED: "NotAuthorizedException",
  RESOURCE_NOT_FOUND: "ResourceNotFoundException",
  TOO_MANY_REQUESTS: "TooManyRequestsException",
  UNEXPECTED_LAMBDA: "UnexpectedLambdaException",
  USER_LAMBDA_VALIDATION: "UserLambdaValidationException",
  USERNAME_EXISTS: "UsernameExistsException",
  MFA_CODE_MISMATCH: "CodeMismatchException",
  MFA_INVALID_PARAM: "InvalidParameterException",
  MFA_ENABLE_SOFTWARE_TOKEN_MFA_EXCEPTION: "EnableSoftwareTokenMFAException",
} as const;

export type AuthErrorCode =
  (typeof AUTH_ERROR_CODES)[keyof typeof AUTH_ERROR_CODES];

// TODO: Import these from libs

export const CONVERSION_IDS = [
  "PROMPTED_AWARENESS-CONSIDERATION",
  "CONSIDERATION-INVESTIGATION",
  "CONSIDERATION-USAGE",
  "CONSIDERATION-PREFERENCE",
  "CONSIDERATION-INVESTIGATION",
  "INVESTIGATION-USAGE",
  "USAGE-PREFERENCE",
] as const;

export const ConversionId = z.enum(CONVERSION_IDS);
export type ConversionId = z.infer<typeof ConversionId>;

/* v8 ignore end */
