import { z } from "zod";

// Each operation associated with a permission gets an entry here.

export const Operations = z.enum([
  "ViewDashboard",
  "ViewAnyDashboard",
  "InviteDashboardAccess",
  "RevokeDashboardAccess",
  "ManageDashboard",
  "DelegateManageDashboard",
  "ModifyDashboardUserAttributes",
  "EnumerateDashboardUsers",
  "EnumerateDashboards",
]);

export type Operations = z.infer<typeof Operations>;
