import { z } from "zod";

import { Email, FromStringified } from "@tracksuit/fundamental-types";

import { Action } from "./actions";

export const Grants = Action.array().or(z.literal("UNRESTRICTED"));

export type Grants = z.infer<typeof Grants>;
export namespace Grants {
  export type In = z.input<typeof Grants>;
}

export const Principal = z.object({
  email: Email,
  grants: FromStringified.pipe(Grants),
});

export type Principal = z.infer<typeof Principal>;
export namespace Principal {
  export type In = z.input<typeof Principal>;
}
