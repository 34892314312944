import React, { useEffect, useState } from "react";

export const useFocusWithin = (ref: React.RefObject<HTMLElement>) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      setIsFocused(node.contains(document.activeElement) ?? false);

      const handleFocusIn = () => setIsFocused(true);
      const handleFocusOut = (e: FocusEvent) =>
        node.contains(e.relatedTarget as Element) || setIsFocused(false);

      node.addEventListener("focusin", handleFocusIn, false);
      node.addEventListener("focusout", handleFocusOut, false);

      return () => {
        node.removeEventListener("focusin", handleFocusIn);
        node.removeEventListener("focusout", handleFocusOut);
      };
    }
  }, [ref]);

  return isFocused;
};
